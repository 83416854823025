.PlaybackMap-panel {
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.PlaybackMap-heading {
  background-color: #363636;
  border-radius: 6px 6px 0 0;
  color: #fff;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
}

.PlaybackMap-body {
  width: 100%;
  background-color: #fff;
  padding: 1.25rem;
  border-radius: 0 0 6px 6px;
}

.PlaybackMap-body img,
.PlaybackMap-body object {
  min-height: 550px;
  width: 100%;
}

.PlaybackMap-panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.PlaybackMap-body svg {
  cursor: pointer;
}

.PlaybackMap-body svg .kickout {
  fill: #e6194b;
}

.PlaybackMap-body svg .kickout.completed {
  fill: #bfef45 !important;
}

.PlaybackMap-body svg .kickout circle {
  r: 0.3;
}

.PlaybackMap-body svg g#orientations {
  opacity: 0;
}

.PlaybackMap-body svg g#positions {
  opacity: 0.1;
}

.PlaybackMap-body .playback-path {
  stroke: #00559f;
  stroke-width: 0.5;
  stroke-linejoin: round;
  stroke-miterlimit: 4;
  stroke-opacity: 0.85;
  fill: none;
}
.PlaybackMap-body svg g#first {
  opacity: 1;
  fill: #009f55;
}

div.tooltip-donut {
  position: absolute;
  text-align: center;
  padding: 0.5rem;
  background: #ffffff;
  color: #313639;
  border: 1px solid #313639;
  border-radius: 8px;
  pointer-events: none;
  font-size: 1.3rem;
  width: 500px;
  z-index: 6;
}

.PlaybackMap-body .map-button {
  max-width: 45px;
  -webkit-transition: max-width 1s ease-in-out 1s;
  transition: max-width 1s ease-in-out 1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
}

.PlaybackMap-body .map-button i {
  margin-right: 1em;
}

.PlaybackMap-body .map-button:hover {
  max-width: 16rem;
}
