.PlanMap-panel {
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.PlanMap-heading {
  background-color: #363636;
  border-radius: 6px 6px 0 0;
  color: #fff;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
}

.PlanMap-body {
  width: 100%;
  background-color: #fff;
  padding: 1.25rem;
  border-radius: 0 0 6px 6px;
}

.PlanMap-body svg {
  cursor: pointer;
}

.PlanMap-body img,
.PlanMap-body svg {
  min-height: 500px;
  width: 100%;
}

.PlanMap-panel:not(:last-child) {
  margin-bottom: 1.5rem;
}

.PlanMap-body svg .kickout {
  fill: red;
}

.PlanMap-body svg .kickout circle {
  r: 0.3;
}

.PlanMap-body .map-button {
  max-width: 48px;
  -webkit-transition: max-width 1s ease-in-out 1s;
  transition: max-width 1s ease-in-out 1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
}

.PlanMap-body .map-button i {
  margin-right: 1em;
}

.PlanMap-body .map-button:hover {
  max-width: 16rem;
}

.PlanMap-body .kickout-button {
  max-width: 45px;
  -webkit-transition: max-width 1s ease-in-out 1s;
  transition: max-width 1s ease-in-out 1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 2px;
}

.PlanMap-body .kickout-button i {
  margin-right: 1em;
}

.PlanMap-body .kickout-button:hover {
  max-width: 30rem;
}

.PlanMap-body .training-path {
  stroke: #00559f;
  stroke-width: 0.5;
  stroke-linejoin: round;
  stroke-miterlimit: 4;
  stroke-opacity: 0.85;
  fill: none;
}

.PlanMap-body .training-path-filled {
  fill: #00559f;
}
