.modal {
  z-index: 5;
}

.modal-content {
  background-color: white;
  width: 90%;
  height: 90%;
  overflow: hidden;
}
