.chart {
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.chart-heading {
  background-color: #363636;
  border-radius: 6px 6px 0 0;
  color: #fff;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
}

.chart-body {
  width: 100%;
  height: 500px;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
}

.chart-table .chart-body .table {
  width: 100%;
}

.chart-table .chart-body {
  overflow-y: auto;
}
.chart-table {
  padding-bottom: 5px;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.chart:not(:last-child) {
  margin-bottom: 1.5rem;
}

.report-button > a,
.report-button > a:hover,
.report-button > a:visited {
  color: inherit;
}
