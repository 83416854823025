.RuntimeChart {
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}

.RuntimeChart > .RuntimeChart-heading {
  background-color: #363636;
  border-radius: 6px 6px 0 0;
  color: #fff;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
}
.RuntimeChart > .RuntimeChart-body {
  width: 100%;
  height: 580px;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
}

.RuntimeChart:not(:last-child) {
  margin-bottom: 1.5rem;
}

.RuntimeChart-no-data {
  height: 580px;
  display: flex;
  align-items: center;
  justify-content: center;
}
