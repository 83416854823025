.KickoutChart {
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
}
.KickoutChart table {
  width: 100%;
}

.KickoutChart .table-heading {
  background-color: #363636;
  border-radius: 6px 6px 0 0;
  color: #fff;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
}

.KickoutChart-body {
  width: 100%;
  background-color: #fff;
  padding: 1.25rem;
  border-radius: 6px;

  max-height: 580px;
  overflow-x: auto;
}

.KickoutChart-body tr {
  cursor: pointer;
}

.KickoutChart:not(:last-child) {
  margin-bottom: 1.5rem;
}
