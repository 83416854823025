.metadata > div {
  display: flex;
  align-items: baseline;
}

.metadata > div > span {
  flex: 2;
}

.metadata > div > span.label {
  flex: 1;
}

#deviceLink {
  color: #3273dc;
  cursor: pointer;
}
#deviceLink:hover {
  color: black;
}
