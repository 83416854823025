.LandingLink {
  cursor: pointer;
}

.LandingLink.is-disabled {
  cursor: not-allowed;
  color: grey !important;
}

.LandingLink.is-disabled h2 {
  cursor: not-allowed;
  color: grey !important;
}

.LandingLink .card-image {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LandingLink .card-content {
  text-align: center;
}
