.MultiselectDropdown .dropdown-menu {
  width: 300px;
  max-height: 600px;
  overflow-y: auto;
}

.MultiselectDropdown
  .dropdown-menu
  .dropdown-content
  .dropdown-item
  .checkbox
  input {
  margin-right: 10px;
}

.MultiselectDropdown .dropdown-menu .dropdown-content .dropdown-item:hover {
  background-color: #fafafa;
}
