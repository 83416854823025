.ViewHeader {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
}
.ViewHeader-title {
  flex: 1;
}

.ViewHeader-id {
  flex: 2;
}

.ViewHeader-id-move-right {
  position: relative;
  right: -30px;
}
.edit {
  cursor: pointer;
  margin-left: 1rem;
  color: hsl(217, 71%, 53%);
  visibility: hidden;
}

.ViewHeader-id:hover .edit {
  visibility: visible;
}
